<template>
    <div v-if="datas">
        <!-- Pop up signifiant que l'intervention va démarré indiquera l'heure d'arrivée théorique -->
        <!-- incluant le top sécurité en attendant de voir le dev du module sécurité sur edison -->
        <v-dialog v-model="displayStart" persistent max-width="500">
            <v-card>
                <v-card-title style="color: white;text-align: center;font-size: 24px;background-color: red;">
                    Temps d'observation préalable (TOP)
                </v-card-title>
                <v-card-text style='font-size:22px;text-align:start;' class='pt-12'>
                    Veuillez vous assurer avant de commencer votre intervention de pouvoir travailler en toute sécurité,
                    en
                    analysant les risques suivants : <br><br>
                    -Plain pied (Eclairage, Obstacle, Sol glissant, Circulation) <br><br>
                    -Gaz (Odeur) <br><br>
                    -Travail en hauteur (stabilité échelle, vide non sécurisé, chute d'objets, rupture supports)
                    <br><br>
                    -Produits dangereux (inhalation, amiante) <br><br>
                    -Environnement (climat, animaux, véhicule, propreté) <br><br>
                    Suite à ces vérifications, si l'intervention présente un risque grave et imminent pour votre
                    sécurité,
                    merci d'informer immédiatement votre chef d'équipe ou votre chef de groupement. <br><br>
                    Veuillez consulter la fiche bâtiment afin de connaitre les spécificités du site sur lequel vous
                    allez
                    intervenir.<v-btn text color="warning" @click="openFicheInfosUtilesIntervention">Ajouter une info
                        utile</v-btn> <br><br>
                    Merci de compléter la fiche bâtiment des éventuels risques détectés lors de votre passage. <v-btn
                        text color="warning" @click="openFicheRisquesIntervention">Signaler un risque</v-btn><br><br>
                    Démarrer l'intervention?
                    <div v-if="risques" style="color: red;">
                        <br>
                        <v-icon color="red" left>mdi-alert</v-icon>
                        <span>Attention ! Risque(s) signalé(s)</span>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="red" tonal @click="displayStart = false" class="mb-2, mr-2">Non</v-btn>
                    <v-tooltip bottom v-if="risques">
                        <template v-slot:activator="{ on }">
                            <v-btn color="success" tonal @click="startInter" v-on="on" class="mb-2, mr-2">
                                <v-icon color="red" left>mdi-alert</v-icon>
                                Oui
                            </v-btn>
                        </template>
                        <span>Attention ! Risque(s) signalé(s)</span>
                    </v-tooltip>

                    <v-btn color="success" tonal @click="startInter" class="mb-2, mr-2" v-else>
                        Oui
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-toolbar-title class='global-main-title'>Coordonnées de l'intervention</v-toolbar-title>
        <v-container class='pt-6 pl-6 pr-6'>
            <v-col>
                <v-row><span class="font-weight-bold mr-2">CODE LOCAL : </span> {{ datas.hierarchie_code }}</v-row>
                <v-row> {{ datas.logement.logement_residence }} {{ datas.logement.logement_acces }}</v-row>
                <v-row> {{ datas.logement.logement_adresse }}</v-row>
                <v-row> {{ datas.logement.logement_cp }} - {{ datas.logement.logement_ville }}</v-row>
            </v-col>
            <v-divider class='ma-3'></v-divider>

            <!-- Formulaire d'affichage -->
            <v-col v-if='!modifyResidentState'>
                <v-row><span class="font-weight-bold mr-2">NOM OCCUPANT : </span> {{ datas.logement.logement_resident }}
                </v-row>
                <v-row><span class="font-weight-bold mr-2">ETAGE : </span> {{ datas.logement.logement_etage }}</v-row>
                <v-row><span class="font-weight-bold mr-2">LOGEMENT : </span> {{ datas.logement.logement_numero
                    }}</v-row>
                <v-row><span class="font-weight-bold mr-2"> MAIL: </span> {{ datas.logement.logement_email }}</v-row>
                <v-row><span class="font-weight-bold mr-2">TELEPHONES : </span></v-row>
                <v-row> {{ datas.logement.logement_telephone1 }} - {{ datas.logement.logement_telephone2 }}</v-row>
            </v-col>

            <!-- Formulaire de modification -->
            <v-form v-if='modifyResidentState' ref="form">
                <v-text-field v-model="datas.logement.logement_resident" label="Occupant"></v-text-field>
                <v-text-field v-model="datas.logement.logement_email" :rules="emailRules" label="Email"></v-text-field>
                <v-text-field v-model="datas.logement.logement_telephone1" :rules="phoneRules" counter
                    label="Telephone1"></v-text-field>
                <v-text-field v-model="datas.logement.logement_telephone2" :rules="phoneRules" counter
                    label="Telephone2"></v-text-field>
                <cx-button label='ENREGISTRER' @click='updateLogement'></cx-button>
            </v-form>

            <cx-button label='MODIFIER' @click='modifyResidentState = true'
                v-if='!modifyResidentState && inter_en_cours'></cx-button>
            <cx-button label="VOIR FICHE INFOS UTILES" color="warning" tonal
                @click='openFicheInfosUtilesIntervention'></cx-button>
            <cx-button label="VOIR FICHE RISQUES" color="warning" tonal
                @click='openFicheRisquesIntervention'></cx-button>
            <v-divider class='ma-3'></v-divider>
        </v-container>
        <v-toolbar-title class='global-main-title'>Références Administrateur</v-toolbar-title>
        <v-container class='pt-6 px-6'>
            <v-col>
                <v-row>{{ datas.logement.client.client_nom }} - {{ datas.logement.client.client_code }}</v-row>
                <v-row><span class="font-weight-bold mr-2">Reference:</span>{{ datas.logement.logement_reference
                    }}</v-row>
                <v-row><span class="font-weight-bold mr-2">n° de Contrat:</span></v-row>
            </v-col>
            <cx-button label='VOIR FICHE INTERVENTION' @click='getFicheIntervention' :loading="loading"></cx-button>
            <cx-button label="VOIR L'HISTORIQUE TECHNIQUE" @click='openHistoriqueTechnique'></cx-button>
            <cx-button label='INTERVENTION IMPOSSIBLE' @click='interventionImpossible'
                v-if='inter_en_cours'></cx-button>
            <div class='d-flex justify-space-between'>
                <cx-button label='PRECEDENT' @click='previous'></cx-button>
                <cx-button label='DEMARRER' @click='beforeStartInter' v-if='!inter_en_cours'></cx-button>
                <cx-button label='SUIVANT' @click='next' v-if='inter_en_cours'></cx-button>
            </div>
        </v-container>

    </div>
</template>
<script>
export default {
    name: 'vx-logements',
    props: {
        dataProvider: {}
    },
    data() {
        return {
            risques: false,
            displayStart: false,
            inter_en_cours: true,
            datas: null,
            modifyResidentState: false,
            emailRules: [
                v => this.$services.powerLib.regex.getRegex('email').test(v) || "L'email n'est pas valide"
            ],
            phoneRules: [
                v => ((this.$services.powerLib.regex.getRegex('phone').test(v)) && (v.length == 10)) || 'Le numéro de téléphone comporte 10 chiffres'
            ],
            loading: false,
        }
    },
    components: {
        'CxButton': () => import('../../components/action/CxButton')
    },
    mounted() {

        this.$services.request.get('api/pwa/mes-interventions/logement/' + this.dataProvider.intervention).then((response) => {
            this.datas = response
            this.getCountRisques()
        }).catch(() => {
            this.$services.powerLib.notification.showSnackbar({
                message: 'Une erreur est survenue lors de la récupération des informations',
                type: 'error'
            })
        })

        this.$services.request.get('api/pwa/mes-interventions/intervention/statut/' + this.dataProvider.intervention).then((response) => {
            this.inter_en_cours = response
        })

    },
    methods: {

        getCountRisques() {
            this.$services.request.get('api/pwa/mes-interventions/logement/' + this.datas.logement.hierarchie.hierarchie_id + '/nombre-risques').then((response) => {
                this.risques = response

            })
        },
        openHistoriqueTechnique() {
            this.$store.commit('TAB_COMPONENT', {
                component: 'vx-historique-technique',
                title: 'Intervention ' + this.dataProvider.intervention,
                dataProvider: { "intervention": this.dataProvider.intervention }
            });
        },
        openFicheInfosUtilesIntervention() {
            this.$store.commit('TAB_COMPONENT', {
                component: 'vx-fiche-infos-utiles-intervention',
                title: 'Intervention ' + this.dataProvider.intervention,
                dataProvider: {
                    "intervention": this.dataProvider.intervention,
                    "datas": this.datas
                }
            });
        },
        openFicheRisquesIntervention() {
            this.$store.commit('TAB_COMPONENT', {
                component: 'vx-fiche-risques-intervention',
                title: 'Intervention ' + this.dataProvider.intervention,
                dataProvider: {
                    "intervention": this.dataProvider.intervention,
                    "datas": this.datas
                }
            });
        },

        interventionImpossible() {
            this.$store.commit('TAB_COMPONENT', {
                component: 'vx-intervention-impossible',
                title: 'Intervention ' + this.dataProvider.intervention,
                dataProvider: { "intervention": this.dataProvider.intervention }
            });
        },
        next() {
            this.$store.commit('TAB_COMPONENT', {
                component: 'vx-recapitulatif-operations',
                title: 'Intervention ' + this.dataProvider.intervention,
                dataProvider: { "intervention": this.dataProvider.intervention }
            });
        },
        previous() {
            this.$store.commit('TAB_COMPONENT', { component: 'vx-interventions', title: 'Mon Activité' })
        },
        getFicheIntervention() {
            this.loading = true;

            this.$services.request.openPdf('api/pwa/mes-interventions/intervention/' + this.dataProvider.intervention)
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.$services.powerLib.notification.showSnackbar({
                        message: 'Une erreur est survenue lors de la récupération de la fiche intervention',
                        type: 'error'
                    })
                })
        },
        updateLogement() {

            this.$services.request.post('api/pwa/mes-interventions/logement', this.datas.logement).then(() => {
                this.$services.powerLib.notification.showSnackbar({
                    message: 'Modification effectuée avec succès',
                    type: 'success'
                })
                this.modifyResidentState = false
            }).catch(() => {
                this.$services.powerLib.notification.showSnackbar({
                    message: 'Une erreur est survenue lors de la modification ',
                    type: 'error'
                })
            })

        },
        beforeStartInter() {
            this.displayStart = true;
        },
        startInter() {

            let data = {
                id: this.dataProvider.intervention
            }

            this.$services.request.post('api/pwa/mes-interventions/intervention/statut', data).then((response) => {

                this.displayStart = false;

                //un int si une intervention est déjà démarrée, null sinon 
                if (response.intervention_started_id) {

                    this.$services.powerLib.notification.showSnackbar({
                        message: 'Une intervention est déja en cours ( N° ' + response.intervention_started_id + ' ) impossible de démarrer cetter intervention',
                        type: 'error'
                    })

                    this.inter_en_cours = false
                } else {
                    this.inter_en_cours = true
                }
            })

        }
    }
}
</script>
