<template>
    <div class='d-flex pt-5 justify-center'>
        <div v-for="(item, index) in interventions" v-bind:key="item.inter_date" style="flex-grow: 1">
            <div class="d-flex justify-space-between pl-3 font-weight-light pt-2 pb-2 subheading white--text bg-success"
                style="background:#25207a;">
                <div v-bind:id='index' class="align-self-center">
                    Intervention du jour - {{ (getBandeauDateInterFormat(index)) }}
                    <v-icon class="ml-6 align-self-center" color="white" style='font-size:40px'
                        @click="getInterventions">refresh</v-icon>
                </div>
            </div>
            <div v-if="loading">
                <div v-for="id of [1, 2, 3, 4]" v-bind:key="id">
                    <v-skeleton-loader type="card">
                    </v-skeleton-loader>
                </div>
            </div>
            <div v-else>
                <div v-for="intervention of item" v-bind:key="intervention.inter_id">
                    <v-card tile color="#E0E0E0" class="mx-auto">
                        <div :style="getBackgroundStyle(intervention.type_cr.cr_code)"
                            class="pl-3 font-weight-light pt-1 pb-2 subheading white--text bg-success">
                            {{ intervention.type_cr.cr_code }} - {{ intervention.inter_id }}
                        </div>
                        <v-container class="pt-0 pb-0" grid-list-md>
                            <v-layout row wrap class="pt-0 mt-2 pr-3 justify-space-between">
                                <v-flex class="pt-2" xs10>
                                    <div class='mb-3'>
                                        <strong>Logement</strong> : {{ getLogement(intervention.logement_numero) }}
                                        <strong>Etage</strong> : {{ getEtage(intervention.logement_etage) }}
                                        <div>
                                            <strong>Téléphone</strong> : {{ getNumeroTelephone(intervention) }}
                                        </div>
                                    </div>

                                    <div class="subheading" style='font-weight:bold;'
                                        v-bind:style="{ 'color': 'black' }">
                                        {{ intervention.logement.logement_resident }}
                                    </div>
                                    <div> {{ intervention.logement.logement_residence }} </div>
                                    <div> {{ intervention.logement.logement_adresse }} </div>
                                    <div> {{ intervention.logement.logement_cp }} {{ intervention.logement.logement_ville }}
                                    </div>
                                    <div style="display: inline-block;">
                                        {{ intervention.segment_horaire.segment_horaire_libelle }}
                                        <div style="display: inline-block;"
                                            v-if='intervention.precision_horaires.length > 0'>
                                            / {{ intervention.precision_horaires[0].commentaire_texte }}
                                        </div>
                                    </div>
                                </v-flex>
                                <v-flex class="pt-0" style='text-align:right;padding-right:10px;' xs2>
                                    <v-icon v-if='intervention.intervention_doc_autre' color="grey"
                                        style='font-size:40px'
                                        @click="openComponent('vx-inter-document', intervention)">folder</v-icon>
                                    <div>
                                        <v-bottom-sheet inset>
                                            <template v-slot:activator="{ on }">
                                                <v-btn small icon>
                                                    <v-icon class='pt-10 pr-2' style='font-size:50px' large
                                                        color="#fd7e14" v-on="on">list</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item-title
                                                    style=" margin:5px; color: #EF6C00; text-align:center;"
                                                    class="subheading">CHOISISSEZ UNE
                                                    ACTION</v-list-item-title>
                                                <v-divider color="#EF6C00"></v-divider>
                                                <v-list-item v-if="!intervention.clotures.length > 0" class='mt-3'
                                                    @click="goToIntervention(intervention)">
                                                    <v-list-item-title style="color: #616161; text-align:center;">
                                                        Accéder à l'intervention </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="openComponent('vx-inter-document', intervention)">
                                                    <v-list-item-title
                                                        style="color: #616161; text-align:center;">Documents
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item v-if="intervention.inter_date_debut != null"
                                                    @click="resetIntervention(intervention)">
                                                    <v-list-item-title
                                                        style="color: #616161; text-align:center;">Réinitialiser
                                                        l'intervention </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-bottom-sheet>
                                        <div class='mb-3'>
                                            <br>
                                            <div class="pl-l pt-5">
                                                <v-icon v-if="intervention.clotures.length > 0" color="green"
                                                    style='font-size:40px'>check_circle_outline</v-icon>
                                                <v-icon
                                                    v-else-if="intervention.inter_date_debut && intervention.clotures.length == 0"
                                                    color="lightorange" style='font-size:40px'>pending</v-icon>
                                                <v-icon v-else color="grey" style='font-size:40px'>schedule</v-icon>
                                            </div>
                                        </div>
                                    </div>
                                </v-flex>
                            </v-layout>
                            <div v-if='intervention.commentaire.length > 0'>
                                <hr>
                                <div class='pb-2 pt-2' style='text-align:left;'> Remarque :
                                    {{ intervention.commentaire[0].commentaire_texte }} </div>
                            </div>
                        </v-container>
                    </v-card>
                </div>
            </div>
        </div>

        <!-- NAVBAR filtre par date finalement non demandé-->
        <v-card class="mx-auto mr-0" width="50" height='100%' style='display:none'>
            <v-navigation-drawer class="deep-purple accent-4" dark permanent>
                <v-list>
                    <v-list-item @click='jourSelectionne = null' class='pa-1'>
                        <v-list-item-content>
                            <v-icon>list</v-icon>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="(jour) in jours" v-bind:key="jour" @click='jourSelectionne = jour' class='pa-1'>
                        <v-list-item-content>
                            <v-list-item-title>{{ getLabelDate(jour) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </v-card>
    </div>
</template>
<script>


export default {
    name: 'vx-interventions',
    data() {
        return {
            jourSelectionne: '',
            loading: false
        }
    },
    computed: {
        interventions() { return this.$store.getters.getInterventions(this.jourSelectionne) },
        jours() { return this.getDateInIntervention() }
    },
    mounted() {
        this.getInterventions()
        this.getDateInIntervention()
    },
    methods: {
        getEtage(data) {
            if (data) {
                return data
            } else {
                return 'Non renseigné'
            }
        },
        getLogement(data) {
            if (data) {
                return data
            } else {
                return 'Non renseigné'
            }
        },
        getNumeroTelephone(intervention) {
            const isValidPhoneNumber = (phone) => {
                // Vérifie que le numéro est une chaîne numérique de 10 caractères
                return this.$services.powerLib.regex.getRegex('phone').test(phone);
            };
            if (intervention.logement_telephone1 && isValidPhoneNumber(intervention.logement_telephone1)) {
                return intervention.logement_telephone1;
            } else if ((!intervention.logement_telephone1 || !isValidPhoneNumber(intervention.logement_telephone1)) && intervention.logement_telephone2 && isValidPhoneNumber(intervention.logement_telephone2)) {
                return intervention.logement_telephone2;
            } else {
                return 'Aucun numéro renseigné ou format incorrect';
            }
        },
        getBackgroundStyle(crCode) {
            let backgroundColor;
            switch (crCode) {
                case 'D3':
                    backgroundColor = 'orange';
                    break;
                case 'DAS':
                    backgroundColor = 'orange';
                    break;
                default:
                    backgroundColor = 'grey';
            }

            return {
                background: backgroundColor,
                height: '30px',
                textAlign: 'center'
            };
        },
        resetIntervention(intervention) {
            this.$services.request.post(`api/pwa/mes-interventions/intervention/${intervention.inter_id}/reset`)
                .then((response) => {
                    this.getInterventions()
                    this.$services.powerLib.notification.showSnackbar({
                        message: 'Intervention réinitialisée avec succès',
                        type: 'success'
                    })
                }).catch(() => {
                    this.$services.powerLib.notification.showSnackbar({
                        message: 'Une erreur est survenue lors de la réinitialisation de l\'intervention',
                        type: 'error'
                    })
                })
        },
        getInterventions() {
            this.loading = true;

            this.$services.request.get('api/pwa/mes-interventions/intervention')
                .then((data) => {
                    this.loading = false;
                    this.$store.commit('UPDATE_INTERVENTION', data)
                })
        },
        openComponent(component, intervention) {
            this.$store.commit('TAB_COMPONENT', {
                title: 'Intervention ' + intervention.inter_id,
                component: component,
                dataProvider: { intervention: intervention.inter_id, clotures: intervention.clotures.length }
            });
        },
        getDateInIntervention() {
            return Object.keys(this.$store.getters.getInterventions())
        },
        getLabelDate(date) {
            let content = this.$services.format.moment.getDate(date, 'DD') + '/' + this.$services.format.moment.getDate(date, 'MM')
            return content
        },
        getBandeauDateInterFormat(date) {
            return this.$services.format.moment.getDate(date, 'DD / MM / YYYY')
        },
        goToIntervention(intervention) {
            this.$services.request.get('/api/phizz/file-management/interventions/' + intervention.inter_id + '/get-files-metadata').then((response) => {
                if (response.length > 0) { //l'intervention contient des documents
                    this.openComponent('vx-inter-document', intervention)
                } else {
                    this.openComponent('vx-logements', intervention)
                }
            })
        }
    }
}
</script>
<style lang="scss">
// DOC : Enlever le ripple effect sur les button
//https://stackoverflow.com/questions/60756963/remove-ripple-effect-on-icon-button
.v-btn {
    &:before {
        opacity: 0 !important;
    }
}

.v-ripple__container {
    opacity: 0 !important;
}

.v-icon {
    &:after {
        opacity: 0 !important;
    }
}
</style>
